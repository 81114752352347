<template>
    <div>
        <h1 class="mt-4">
            dev &raquo; translator
        </h1>

        <div class="mb-3">
            <b-row class="mb-2 mt-4">
                <b-col offset="9" cols="3">
                    <b-input-group class="oggy-search mb-3">
                        <b-form-input v-model="search" trim :placeholder="$t('globals.input.placeholder-search')" />
                        <b-input-group-append>
                            <b-input-group-text><i class="fa fa-search text-secondary" /></b-input-group-text>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-table class="oggy-table" fixed :items="filteredLocales" :fields="fields">
                <template v-slot:cell(key)="l">
                    <b-form-input v-model="l.item.key" size="sm" placeholder="Require key" :state="stateKey(l.item.key)" />
                </template>

                <template v-slot:cell(source)="l">
                    <b-form-textarea v-model="l.item.source" max-rows="10" :class="{ empty: classEmpty(l.item.source) }" />
                </template>

                <template v-slot:cell(target)="l">
                    <b-form-textarea v-model="l.item.target" max-rows="10" :class="{ empty: classEmpty(l.item.target) }" />
                </template>

                <template v-slot:cell(action)="l">
                    <b-button class="p-1" variant="default" @click="del(l.index)"><i class="fa fa-trash"></i></b-button>
                    <br />
                    <b-button class="p-1" variant="default" @click="add(l.index)"><i class="fa fa-plus-circle"></i></b-button>
                </template>
            </b-table>

            <b-row class="mb-2 mt-4">
                <b-col cols="6">
                    <div class="float-right">
                        <b-button class="ml-2" :disabled="!correctJson(form.source) || !correctJson(form.target)" variant="success" @click="importAll">Import all</b-button>
                    </div>

                    <h5>
                        Source
                        <b-button class="ml-2" :disabled="!correctJson(form.source)" @click="importSource">Import</b-button>
                        <b-button class="ml-2" @click="cleanSource">Clean</b-button>
                        <b-button class="ml-2" @click="downloadSource">Download</b-button>
                    </h5>

                    <b-form-textarea v-model="form.source" :state="stateJson(form.source)" rows="10" max-rows="100" />
                </b-col>
                <b-col cols="6">
                    <div class="float-right">
                        <b-button class="ml-2" variant="danger" @click="removeAll">Remove all</b-button>
                    </div>

                    <h5>
                        Target
                        <b-button class="ml-2" :disabled="!correctJson(form.target)" @click="importTarget">Import</b-button>
                        <b-button class="ml-2" @click="cleanTarget">Clean</b-button>
                        <b-button class="ml-2" @click="downloadTarget">Download</b-button>
                    </h5>
                    <b-form-textarea v-model="form.target" :state="stateJson(form.target)" rows="10" max-rows="100" />
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.empty {
    background-color: #ddd;
}
.form-control {
    border-color: #cdd7e7;
    &:focus {
        border-color: #cdd7e7;
    }
    &.is-invalid {
        border-color: #dc3545;
        background-color: #ffdfe2;
        &:focus {
            border-color: #dc3545;
        }
    }
}
</style>

<script>
import _ from 'lodash';
import t from '@/tool';

export default {
    data() {
        return {
            search: '',
            fields: [{ key: 'key', thStyle: 'width: 10rem' }, { key: 'source' }, { key: 'target' }, { key: 'action', label: '', thStyle: 'width: 3rem' }],
            form: {
                source: `{\n    "hello-world": "Hello world!"\n}`,
                target: `{\n    "hello-world": "Hallo Welt!"\n}`
            },
            locales: []
        };
    },
    computed: {
        filteredLocales() {
            const search = t.replaceSearch(this.search);
            return this.locales.filter(l => `${l.key}\n${l.source}\n${l.target}`.match(new RegExp(search, 'i')));
        },
        stateJson() {
            return this.correctJson;
        },
        stateKey() {
            return key => {
                if (!key) {
                    return false;
                }
                if (key.split('.').filter(k => k === '').length) {
                    return false;
                }
                if (this.locales.filter(l => l.key === key).length !== 1) {
                    return false;
                }
                if (this.locales.filter(l => l.key.substr(0, key.length + 1) === `${key}.`).length) {
                    return false;
                }
                return null;
            };
        },
        classEmpty() {
            return obj => (_.isString(obj) && obj.length ? false : true);
        }
    },
    created() {
        this.importSource();
        this.importTarget();
    },
    methods: {
        parseJSON(json) {
            try {
                return JSON.parse(json);
            } catch (e) {
                return false;
            }
        },

        correctJson(json) {
            return !!_.isPlainObject(this.parseJSON(json));
        },

        add(i) {
            this.locales.splice(i + 1, 0, { key: '' });
        },

        del(i) {
            this.locales.splice(i, 1);
        },

        import(data) {
            const l = {};

            const tree = (t, d) => {
                _.each(t, (s, k) => {
                    d.push(k);
                    if (_.isString(s)) {
                        l[d.join('.')] = s;
                    } else if (_.isPlainObject(s)) {
                        tree(s, d);
                    }
                    d.pop();
                });
            };
            tree(data, []);
            return l;
        },

        importSource() {
            const data = this.parseJSON(this.form.source);
            _.each(this.import(data), (source, key) => {
                const l = _.find(this.locales, { key });
                if (l) {
                    this.$set(l, 'source', source);
                } else {
                    this.locales.push({ key, source });
                }
            });
        },
        downloadSource() {
            const data = {};
            _.each(this.locales, l => {
                if (l.key && l.source) {
                    _.set(data, l.key, l.source);
                }
            });
            this.form.source = JSON.stringify(data, null, 4);
        },
        cleanSource() {
            _.each(this.locales, l => {
                this.$delete(l, 'source');
            });
        },

        importTarget() {
            const data = this.parseJSON(this.form.target);
            _.each(this.import(data), (target, key) => {
                const l = _.find(this.locales, { key });
                if (l) {
                    this.$set(l, 'target', target);
                } else {
                    this.locales.push({ key, target });
                }
            });
        },
        downloadTarget() {
            const data = {};
            _.each(this.locales, l => {
                if (l.key && l.target) {
                    _.set(data, l.key, l.target);
                }
            });
            this.form.target = JSON.stringify(data, null, 4);
        },
        cleanTarget() {
            _.each(this.locales, l => {
                this.$delete(l, 'target');
            });
        },

        removeAll() {
            this.locales = [];
        },
        importAll() {
            this.removeAll();

            const source = this.parseJSON(this.form.source) || {};
            const target = this.parseJSON(this.form.target) || {};
            _.each(this.import(_.defaultsDeep(source, target)), (v, key) => {
                if (!_.find(this.locales, { key })) {
                    this.locales.push({ key });
                }
            });
            this.importTarget();
            this.importSource();
        }
    }
};
</script>
